#Reports {
  display: grid;
  grid-template-rows: 30px 100px 1fr;
  grid-template-areas:
    'report-types'
    'date-range'
    'content';

  box-sizing: border-box;
  padding: 20px;

  background-color: var(--background-dark);
}

#report-types {
  display: flex;
  flex-direction: row;
  justify-content: center;

  grid-area: report-types;
}

#report-types .button {
  width: 150px;
}

#report-types .button:not(:last-child) {
  margin-right: 10px;
}

#date-selection {
  grid-area: date-range;
}

#date-range {
  margin-top: 20px;

  text-align: center;
}

#date-range .range .begin {
  margin-right: 20px;
}

#date-range .error {
  margin-top: 20px;

  color: var(--color-high);
}

#data-archive-grid {
  text-align: center;
}

#content {
  grid-area: content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#content .header {
  font-weight: 700;
}

#content .cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: var(--font-color);
}

#content .cell.stripe {
  background-color: var(--background-light);
}

#coming-soon {
  font-size: 2rem;
}
