#Forecast {
  display: flex;
  flex-direction: column;
  background-color: var(--background-dark);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  padding-top: 20px;
}

@media screen and (max-width: 600px) {
  #ForecastDetailed {
    margin-bottom: 40px;
    order: 1;
  }

  #ForecastShort {
    order: 2;
  }
}

#ForecastShort {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  margin-bottom: 20px;
}

.ShortDayCard {
  min-width: 150px;
  text-align: center;
}

.ShortDayCard img {
  margin-bottom: 5px;
  margin-top: 10px;
}

.ShortDayCard .shortForecast {
  max-width: 180px;
  margin-bottom: 10px;
  overflow-wrap: wrap;
}

.ShortDayCard .temperature.daytime {
  color: var(--color-high);
}

.ShortDayCard .temperature.nighttime {
  color: var(--color-low);
}

.Name {
  font-weight: bold;
}

#ForecastDetailed tr td {
  padding: 10px;
}

#ForecastDetailed tbody tr:nth-child(odd) {
  background-color: var(--background-light);
}
