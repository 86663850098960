:root {
  --font-color: #bbbbbb;
  --background-dark: #151515;
  --background-light: #212121;
  --border: #333333;
  --color-accent: #2196f3;
  --color-high: #cc0022;
  --color-low: #115599;

  --material-ui-palette-primary1: var(--color-accent);
  --material-ui-palette-primary2: var(--color-accent);
  --material-ui-palette-alternate-text: #111111;
}

body {
  margin: 0;
  padding: 0;

  background-color: var(--background-light);

  font-family: 'Roboto', sans-serif;
  color: var(--font-color);
  letter-spacing: 0.5px;
}
