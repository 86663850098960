.DateRange {
  grid-area: highs-lows-date-range;

  box-sizing: border-box;
  padding: 20px 0;
  margin: 0 auto;

  background-color: var(--background-dark);
}

.date-range-title {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

.pickers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.presets {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  justify-content: center;
  grid-gap: 10px;

  margin-bottom: 20px;
}
