.loading {
  width: 100%;
  height: 100%;
}

#Current {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  grid-template-areas: 'content-left content-right';
}

.weather-station {
  grid-area: content-left;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100px 1fr;

  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);

  background-color: var(--background-dark);
}

.weather-station .data {
  grid-column: 1 / -1;
  grid-row: 2 / 3;

  padding: 0 20px;
  padding-bottom: 20px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.weather-station .data .temperature {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
}

.weather-station .data .additional-data {
  display: grid;

  box-sizing: border-box;
  border: 1px solid var(--border);
}

.highs-lows {
  grid-area: content-right;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 80px 1fr;

  padding: 20px;

  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);

  background-color: var(--background-dark);
}

.highs-lows .temperatures {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
}

.highs-lows .data {
  display: grid;

  background-color: var(--background-light);

  border: 1px solid var(--border);

  font-size: 0.9rem;
}

.highs-lows .data .header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  box-sizing: border-box;
  border-bottom: 1px solid var(--border);

  padding: 10px 0;

  font-size: 0.9rem;
  text-align: center;
}

.highs-lows .data .header .high {
  color: var(--color-high);
}

.highs-lows .data .header .low {
  color: var(--color-low);
}

.highs-lows .data .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  box-sizing: border-box;
  padding: 10px;
}

.highs-lows .data .row:not(:last-child) {
  border-bottom: 1px solid var(--border);
}

.highs-lows .data .row :not(.name) {
  text-align: center;
}

.highs-lows .data .row .high .time {
  font-size: 0.7rem;
}

.highs-lows .data .row .low .time {
  font-size: 0.7rem;
}

.other-location {
  grid-column: 1 / -1;
  grid-row: 3 / 4;

  padding: 20px;

  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);

  background-color: var(--background-dark);
}

@media (max-width: 700px) {
  #Current {
    grid-template-columns: 1fr;
    grid-template-areas:
      'content-left'
      'content-right';
  }
}

@media (max-width: 1250px) {
  .weather-station .data {
    grid-template-columns: 1fr;
    grid-template-rows: 500px 1fr;
  }

  .highs-lows {
    grid-template-columns: 1fr;
    grid-template-rows: 80px 500px 1fr;
  }
}
