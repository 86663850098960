.data-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: center;

  box-sizing: border-box;
  padding: 7px;
  border-bottom: 1px solid var(--border);

  font-size: 0.9rem;

  background-color: var(--background-light);
}

.data-item:last-child {
  border-bottom: 0;
}
