.loading {
  width: 100%;
  height: 100%;
}

#History {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-template-areas:
    'title title title title'
    'history-date-time history-date-time highs-lows-date-range highs-lows-date-range'
    'history-date-time-data history-date-time-data temperatures history-data';

  background-color: var(--background-dark);
}

.history-temperatures {
  grid-area: temperatures;

  display: grid;
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}

.history-data {
  grid-area: history-data;

  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);

  margin: 0 20px 20px 20px;

  box-sizing: border-box;
  border: 1px solid var(--border);

  font-size: 0.9rem;
}

.history-data .header,
.history-data .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;

  box-sizing: border-box;
  padding: 10px 0;

  background-color: var(--background-light);
}

.history-data .header > *,
.history-data .row > * {
  box-sizing: border-box;
  padding: 0 10px;

  text-align: center;
}

.history-data .header,
.history-data .row:not(:last-child) {
  border-bottom: 1px solid var(--border);
}

.history-data .header .high {
  color: var(--color-high);
}

.history-data .header .low {
  color: var(--color-low);
}

.history-data .row .description {
  justify-self: start;
}

.history-data .row .high .date,
.history-data .row .high .time {
  font-size: 0.7rem;
}

.history-data .row .low .date,
.history-data .row .low .time {
  font-size: 0.7rem;
}

.history-error {
  grid-column: 1 / -1;

  margin: 20px 0;

  color: var(--color-high);
  text-align: center;

  background-color: var(--background-dark);
}

.date-range-error {
  grid-column: 3 / 6;

  color: var(--color-high);
  text-align: center;

  background-color: var(--background-dark);
}

.history-date-time {
  grid-area: history-date-time;

  display: grid;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.history-date-time-pickers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.history-date-time-data {
  grid-area: history-date-time-data;

  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);

  margin: 0 auto 20px auto;

  box-sizing: border-box;
  border: 1px solid var(--border);
}

@media (max-width: 700px) {
  #History {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'highs-lows-date-range'
      'history-data'
      'history-date-time'
      'history-date-time-data';
  }

  .history-temperatures {
    display: none;
  }
}
