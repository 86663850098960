.NavBar {
  --background-color: var(--background-dark);
  grid-area: header;

  display: flex;
  flex-direction: row;

  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);

  background-color: var(--background-color);
}

.NavBar a {
  text-decoration: none;
}

.NavBar .button {
  width: 150px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  border-bottom: 3px solid var(--background-color);
  transition: border-bottom 0.2s ease-in-out;

  font-size: 1.2rem;
  font-weight: 300;
  color: var(--font-color);

  cursor: pointer;
}

.NavBar a.active .button {
  box-sizing: border-box;
  border-bottom: 3px solid var(--color-accent);
  transition: border-bottom 0.2s ease-in-out;
}

@media (max-width: 700px) {
  .NavBar {
    overflow-x: auto;
  }
}

@media (min-width: 701px) {
  .NavBar {
    justify-content: center;
  }
}
