.App {
  height: 100vh;

  display: grid;
  grid-template-rows: 70px 1fr;
  grid-gap: 20px;
  grid-template-areas:
    'header'
    'content';
}

.loading {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;

  grid-column: 1 / -1;
}

.error {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;

  grid-column: 1 / -1;
}

@media (max-width: 700px) {
  .App {
    grid-template-areas:
      'header'
      'content';
  }
}
